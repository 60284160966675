/* OtpCodeCompo.css */
.otpContainer {
    display: flex;
    justify-content: space-between;
    gap: 10px; /* Adjust this value to set the desired gap between inputs */
}

.otpInput {
    width: 60%; /* Adjust this value to set the width of each input */
    height: 30px; /* Adjust this value to set the height of each input */
    font-size: 25px; /* Adjust this value to set the font size */
    text-align: center;
    border: 3px solid grey; /* Set the default border color */
    color: #0A2252;
    font-weight: 500;
    font-family: 'poppins';
}

.otpInput:focus {
    border: 1.5px solid #5076FF !important; /* Define the active border color */
    outline: none; /* Remove default outline on focus */
}
@media only screen and (max-width: 768px) {
    .otpContainer {
        display: flex;
        justify-content: space-between;
        gap: 5px; /* Adjust this value to set the desired gap between inputs */
        margin: auto;
    }

    .otpInput {
        width: 30%; /* Adjust this value to set the width of each input */
        height: 15px; /* Adjust this value to set the height of each input */
        font-size: 15px; /* Adjust this value to set the font size */
        text-align: center;
        border: 3px solid grey; /* Set the default border color */
        color: #0A2252;
        font-weight: 500;
        font-family: 'poppins';
    }
}
